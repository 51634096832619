<template>
  <b-card>
    <b-tabs>
      <b-tab active>
        <template #title>
          <feather-icon
            icon="CheckSquareIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">To-do List</span>
        </template>
        <task />
      </b-tab>
      <!-- Tab: Calendar -->
      <b-tab lazy v-if="false">
        <template #title>
          <feather-icon icon="CalendarIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Calendar</span>
        </template>
        <calendar class="mt-2 pt-75" />
      </b-tab>
      <!-- Tab: Work From Home -->
     <!--  <b-tab lazy>
        <template #title>
          <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">請假記錄</span>
        </template>
        <leave-list />
      </b-tab> -->
    <!--   <b-tab lazy>
        <template #title>
          <feather-icon
            icon="CheckCircleIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">加班記錄</span>
        </template>
        <overtime class="mt-2 pt-75" />
      </b-tab> -->
     <!--  <b-tab lazy>
        <template #title>
          <feather-icon icon="HomeIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">在家工作記錄</span>
        </template>
        <work class="mt-2 pt-75" />
      </b-tab> -->

      <!--Tab: 請假請求 
            <b-tab>
                <template #title>
                <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50"/>
                <span class="d-none d-sm-inline">請假請求</span>
                </template> 
                <leave class="mt-2 pt-75" />
            </b-tab>
            -->
      <!--Tab: Time Sheet -->
    </b-tabs>
  </b-card>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink } from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import Calendar from "./calendar/Calendar.vue";
import Leave from "./leave/leaves-add/LeavesAdd.vue";
import Overtime from "./overtime/overtimes-list/OvertimesList.vue";
import Work from "./work/works-list/WorksList.vue";
import LeaveList from "./leave/leaves-list/LeaveRecordsList.vue";
import Task from "@/views/task/tasks-list/TasksList.vue";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    //Calendar
    Calendar,
    Leave,
    Overtime,
    Work,
    LeaveList,
    Task,
  },
  setup() {},
};
</script>

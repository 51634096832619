import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ability from '@/libs/acl/ability'

export default function useOvertimesList() {

    //User toast
    const toast = useToast()

    const refOvertimeListTable = ref(null)

    const tableColumns = [
        {key: 'id', sortable: true},
        {key: 'name', stickyColumn: true, sortable:true},
        {key: 'title', label:'Title', sortable:true},
        {key: 'remark', label:'REMARK', sortable: true},
        {key: 'off_work_bit', label: '假日/非Working Hour OT'},
        {key: 'work_from', label: '上班時間'},
        {key: 'work_to', label: '放工時間'},
        {key: 'time_from'},
        {key: 'time_to'},
        {key: 'total_hour'},
        {key: 'admin_remark'},
        {key: 'approve_bit', label:'Approved'},
        {key: 'action'}
    ]
    //Filter
    const dateFromFilter = ref(null)
    const dateToFilter = ref(null)

    const perPage = ref(10)
    const totalOvertimes = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)

    const dataMeta = computed(() => {
        const localItemsCount = refOvertimeListTable.value ? refOvertimeListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalOvertimes.value,
        }
    })

    const refetchData = () => {
        refOvertimeListTable.value.refresh()
    }

    watch([currentPage, perPage, searchQuery, dateFromFilter, dateToFilter], () => {
        refetchData()
    })

    const fetchOvertimes = (ctx, callback) => {
        store.dispatch('admin-overtime/fetchOvertimes',{
            q: searchQuery.value,
            perPage: perPage.value,
            page: currentPage.value,
            sortBy: sortBy.value,
            sortDesc: isSortDirDesc.value,
            is_personal: true,
            date_from: dateFromFilter.value,
            date_to: dateToFilter.value,
        })
        .then(response =>{
            const {overtimes, total} = response.data
            callback(overtimes)
            totalOvertimes.value = total
        
        })
        .catch((error) => {
            toast({
                component: ToastificationContent,
                props: {
                    title: 'Error fetching overtime list',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger'
                }
            })
        })
    }

    return {
        fetchOvertimes,
        tableColumns,
        perPage,
        currentPage,
        totalOvertimes,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refOvertimeListTable,
        ability,
        refetchData,
        dateFromFilter,
        dateToFilter,
    }

}
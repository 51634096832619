import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchLeaveRecords(ctx, queryParams) {
        return new Promise((resolve, reject) => {
          axiosIns.get('/leave_record/list', {params:queryParams})
          .then(response => resolve(response))
          .catch(error => reject(error))
        })
    },
    fetchLeaveRecord(ctx, id) {
      return new Promise((resolve, reject) => {
        axiosIns.get(`/leave_record/${id}`)
        .then(response => resolve(response))
        .catch(error => reject(error))
      })
    },
    addLeaveRecord(ctx, data) {
        return new Promise((resolve, reject) => {
            axiosIns.post( '/leave_record',
            data,
            {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
            }
          )
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
    },
    deleteLeaveRecord(ctx,queryParams)
    {
      return new Promise((resolve, reject) => {
        axiosIns.delete('/leave_record', {params:queryParams})
        .then(response => resolve(response))
        .catch(error => reject(error))
      })
    }

  },
}

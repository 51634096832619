<template>
  <div>
    <modal-calendar />
    <leave-record-add-new
      :is-add-new-leave-record-sidebar-active.sync="
        isAddNewLeaveRecordSidebarActive
      "
      :id="id"
      :data="leaverecordData"
      :leavecodes="leavecodes"
      :holidays="holidays"
      @refetch-data="refetchData"
    >
    </leave-record-add-new>

    <modal-leave @refetch-data="refetchData" />
    <div class="annual-leave-statistic-container" v-if="user && user.day_of_employment && dateFromFilter">
    <div class="row">
        <div :class="`col-md-${3+((show_date_remain)?0:1)}`">
            <div class="stat-box">
                <div class="title pos">{{total_annual_leave}}</div>
                <div class="body">總年假天數</div>
            </div>
        </div>
        <div :class="`col-md-${3+((show_date_remain)?0:1)}`">
            <div class="stat-box">
                <div class="title neg">{{annual_leave_used}}</div>
                <div class="body">已用年假天數</div>
            </div>
        </div>
        <div :class="`col-md-${3+((show_date_remain)?0:1)}`">
            <div class="stat-box">
                <div class="title pos">{{total_annual_leave - annual_leave_used}}</div>
                <div class="body">剩餘年假天數</div>
            </div>
        </div>

        <div :class="`col-md-${3+((show_date_remain)?0:1)}`" v-if="show_date_remain">
            <div class="stat-box">
                <div class="title pos">{{date_remain}}</div>
                <div class="body">剩餘天數</div>
            </div>
        </div>
    </div>
</div>


  <b-row>
    <b-col cols="12" md="5" sm="11" class="mb-md-0 mb-2">
      <label>Date From</label>
      <b-input-group>
        <b-form-datepicker
          id="leave_date_from"
          placeholder="Date From"
          local="en"
          v-model="dateFromFilter"
          :date-format-options="{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          }"
        />
      </b-input-group>
    </b-col>
    <b-col cols="12" md="5"  class="mb-md-0 mb-2">
      <label>Date To</label>
      <b-form-datepicker
        id="leave_date_to"
        placeholder="Date To"
        local="en"
        :value="dateToFilter"
        @input="(val) => $emit('update:dateToFilter', val)"
        :date-format-options="{
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        }"
      />
    </b-col>
    <b-col
      cols="12"
      md="1"
      class="d-flex align-items-end justify-content-center"
    >
    <b-button variant="primary" class="d-inline-block ml-1 mobile-100" @click="setPreviousYear">
      <feather-icon icon="ArrowLeftIcon" size="18" class="" />
    </b-button>
      <b-button variant="primary" class="d-inline-block ml-1 mobile-100"  @click="setNextYear">
        <feather-icon icon="ArrowRightIcon" size="18" class="" />
      </b-button>
    </b-col>
     <b-col
      cols="12"
      md="3"
      class="d-flex justify-content-center align-items-center"
    >
    
    </b-col>
  </b-row>

    <b-card no-body class="mb-0">
      
      <div class="mt-2 mb-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
          <h4>入職日期: <span class="text-success">{{user.day_of_employment}}</span></h4>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <!--   <b-form-input v-model="searchQuery" class="d-inline-block mr-1 mobile-100" placeholder="搜尋..." /> -->
              <b-button variant="primary" v-b-modal.modal-leave>
                <span class="text-nowrap">新增請假記錄</span>
              </b-button>

              <b-button
                variant="primary"
                v-b-modal.modal-user-calendar
                class="d-inline-block ml-1 mobile-100"
              >
                <feather-icon icon="CalendarIcon" size="18" class="" />
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refLeaveRecordListTable"
        class="position-relative"
        :items="fetchLeaveRecords"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(full_day_bit)="data">
          <feather-icon
            v-if="data.item.full_day_bit"
            icon="CheckIcon"
            size="18"
            class="mr-50 text-success"
          />
        </template>
        <template #cell(file_path)="data">
          <div class="block" v-if="data.item.file_path">
            <el-image
              style="width: 100px; height: 100px"
              :src="getImagePath(data.item.file_path)"
              :preview-src-list="getList(getImagePath(data.item.file_path))"
              fit="fill"
            ></el-image>
          </div>
        </template>
        <template #cell(count_al_balance)="data">
          <feather-icon
            v-if="data.item.count_al_balance"
            icon="CheckIcon"
            size="18"
            class="mr-50 text-success"
          />
        </template>
        <template #cell(approve_bit)="data">
          <feather-icon
            v-if="data.item.approve_bit"
            icon="CheckIcon"
            size="18"
            class="mr-50 text-success"
          />
        </template>
        <template #cell(action)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
            v-if="!data.item.approve_bit"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-if="!data.item.approve_bit"
              @click="handleEdit(data.item)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="!data.item.approve_bit"
              @click="confirmDelete(data.item.id)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalLeaveRecords"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormDatepicker,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import leaverecordStoreModule from "@/views/admin/leaverecord/leaverecordStoreModule";
import leavecodeStoreModule from "@/views/setting/leavecode/leavecodeStoreModule";
import useLeaveRecordsList from "./useLeaveRecordsList";
import LeaveRecordAddNew from "./LeaveRecordListAddNew.vue";
import LeaveAdd from "../leaves-add/LeavesAdd.vue";
import ModalLeave from "../leaves-modal/ModalLeave.vue";
import holidayStoreModule from "@/views/setting/holiday/holidayStoreModule.js";
import ModalCalendar from "../modal/ModalCalendar.vue";
import StatisticCardVertical from "../../../admin/leaverecord/leaverecords-list/StatisticCardVertical.vue";
import { getNextCycleEndDate, getPreviousYear, getNextYear, dateDifferent } from "@/libs/helper";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    LeaveRecordAddNew,
    LeaveAdd,
    ModalLeave,
    ModalCalendar,
    BFormDatepicker,
    StatisticCardVertical,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
  },
  mounted(){
    this.dateToFilter = getNextCycleEndDate(this.dateFromFilter);
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    },
    current_employment_cycle() {
      return this.user.current_employment_cycle;
    },
    annual_leave_used(){
        //sum of the leave_records
        let annual_leave_used = this.leave_records.reduce((accumulator, currentValue) => {
          if(currentValue.count_al_balance)
          {
            return accumulator+currentValue.total_days
          }else{
            return accumulator
          }
        }, 0);

       return  annual_leave_used
    },
    total_annual_leave(){
      if(this.dateToFilter)
      {
        let original_annual_leave = this.user.annual_leave_entitlement || 12

        return Math.max(original_annual_leave,Math.min(Math.round(dateDifferent(this.user.day_of_employment, this.dateToFilter)/365)-1 + original_annual_leave,21))
      }else{
        return original_annual_leave;
      }
    },
    date_remain(){
      //get current date string
      let today = new Date();
      return dateDifferent(today, this.dateToFilter)
    },
    show_date_remain(){
      return this.date_remain>=0 && this.date_remain<=365
    }
  },
  watch: {
    current_employment_cycle() {
      this.dateFromFilter = this.current_employment_cycle;
    },
    dateFromFilter(newVal, oldVal) {
      this.dateToFilter = getNextCycleEndDate(newVal);
      //newVal is a date, i would like to get newVal + 1 year - 1 day
    }
  },
  methods: {
    setPreviousYear(){
      if(this.dateFromFilter)
      {
        this.dateFromFilter = getPreviousYear(this.dateFromFilter)
      }
    },
    setNextYear(){
      if(this.dateFromFilter)
      {
        this.dateFromFilter = getNextYear(this.dateFromFilter)
      }
    },
    getList(item) {
      return [item];
    },
    getImagePath(file_path) {
      return process.env.VUE_APP_IMAGE_URL + file_path;
    },
    handleCreate() {
      this.id = 0;
      this.resetLeaveRecordData();
      this.isAddNewLeaveRecordSidebarActive = true;
    },
    confirmDelete(id) {
      this.$swal({
        title: "確認刪除?",
        text: "您的行動是最終決定，您將無法檢索該請假記錄。",
        showCancelButton: true,
        confirmButtonText: "刪除",
        cancelButtonText: '取消',
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("admin-leaverecord/deleteLeaveRecord", { id: id })
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.refetchData();
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    handleEdit(item) {
      console.log(item);
      this.id = item.id;
      this.leaverecordData = { ...item };
      this.isAddNewLeaveRecordSidebarActive = true;
    },
    resetLeaveRecordData() {
      this.leaverecordData = {
        id: 0,
        admin_remark: "",
        total_days: 0,
        date_from: "",
        date_to: "",
        time_from: "",
        time_to: "",
        count_al_balance: false,
        approve_bit: false,
        full_day_bit: false,
      };
    },
  },
  data() {
    return {
      id: 0,
      leaverecordData: {},
    };
  },
  setup() {
    const ADMIN_LEAVE_RECORD_STORE_MODULE_NAME = "admin-leaverecord";
    const SETTING_LEAVE_CODE_STORE_MODULE_NAME = "setting-leavecode";
    const SETTING_HOLIDAY_STORE_MODULE_NAME = "setting-holiday";
    const leavecodes = ref([]);
    const holidays = ref([]);

    if (!store.hasModule(ADMIN_LEAVE_RECORD_STORE_MODULE_NAME))
      store.registerModule(
        ADMIN_LEAVE_RECORD_STORE_MODULE_NAME,
        leaverecordStoreModule
      );
    if (!store.hasModule(SETTING_LEAVE_CODE_STORE_MODULE_NAME))
      store.registerModule(
        SETTING_LEAVE_CODE_STORE_MODULE_NAME,
        leavecodeStoreModule
      );

    if (!store.hasModule(SETTING_HOLIDAY_STORE_MODULE_NAME))
      store.registerModule(
        SETTING_HOLIDAY_STORE_MODULE_NAME,
        holidayStoreModule
      );

    onUnmounted(() => {
      if (store.hasModule(ADMIN_LEAVE_RECORD_STORE_MODULE_NAME))
        store.unregisterModule(ADMIN_LEAVE_RECORD_STORE_MODULE_NAME);
      if (store.hasModule(SETTING_LEAVE_CODE_STORE_MODULE_NAME))
        store.unregisterModule(SETTING_LEAVE_CODE_STORE_MODULE_NAME);
      if (store.hasModule(SETTING_HOLIDAY_STORE_MODULE_NAME))
        store.unregisterModule(SETTING_HOLIDAY_STORE_MODULE_NAME);
    });

    const leaveOptions = ref([]);

    store
      .dispatch("app/fetchOptionList", {
        leave_code: true,
        holiday: true,
        leave_code: true,
      })
      .then((response) => {
        leaveOptions.value = response.data.leave_codes;
        holidays.value = response.data.holidays;
        leavecodes.value = response.data.leave_codes;
      });

    const isAddNewLeaveRecordSidebarActive = ref(false);

    const {
      fetchLeaveRecords,
      tableColumns,
      perPage,
      currentPage,
      totalLeaveRecords,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refLeaveRecordListTable,
      refetchData,
      dateFromFilter,
      dateToFilter,
      leaveFilter,
      ability,
      leave_statistics,
      leave_records,
    } = useLeaveRecordsList();

    dateFromFilter.value = store.state.user.user.current_employment_cycle;

    return {
      //Sidebar
      isAddNewLeaveRecordSidebarActive,

      fetchLeaveRecords,
      tableColumns,
      perPage,
      currentPage,
      totalLeaveRecords,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refLeaveRecordListTable,
      refetchData,
      avatarText,
      ability,
      leavecodes,
      holidays,
      leaveOptions,
      dateFromFilter,
      dateToFilter,
      leaveFilter,
      leave_statistics,
      leave_records,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

@import url('https://fonts.googleapis.com/css?family=Lobster|Quicksand');


.annual-leave-statistic-container {
    margin-top: 50px;
    background-color: #fff;
    box-shadow: 0 2px 5px 0 rgba(180, 180, 180, 0.5);
    margin-bottom: 50px;
    border-radius: 10px;
}

.stat-box {
    padding: 20px;
    text-align: center;
    
    .title {
        font-family: 'Lobster', cursive;
        font-size: 2em;
        
        &.pos {
            color: #00B16A;
        }
        
        &.neg {
            color: #f03434;
        }
    }
    
    .body {
        font-size: 18px;
        color: #555;
    }
}
</style>


import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ability from '@/libs/acl/ability'

export default function useLeaveRecordsList() {

    //User toast
    const toast = useToast()

    const refLeaveRecordListTable = ref(null)

    const tableColumns = [
        {key: 'id', label: '編號', sortable: true},
        {key: 'name', label: '姓名', stickyColumn: true, sortable: true},
        {key: 'description', label: '請假類型', sortable: true},
        {key: 'reason', label: '原因', sortable: true},
        {key: 'date_from', label: '開始日期', sortable: true},
        {key: 'date_to', label: '結束日期', sortable: true},
        {key: 'time_from', label: '開始時間'},
        {key: 'time_to', label: '結束時間'},
        {key: 'full_day_bit', label: '是否全天', sortable: true},
        {key: 'count_al_balance', label: '計算AL餘額'},
        {key: 'total_days', label: '請假總天數'},
        {key: 'file_path', label: '檔案'},
        {key: 'approve_bit', label: '是否批准', sortable: true},
        {key: 'admin_remark', label: '管理員備註'},
        {key: 'action', label: '操作'}
    ];

    //Filter
    const dateFromFilter = ref(null)
    const dateToFilter = ref(null)
    const leaveFilter = ref(null)

    const perPage = ref(1000)
    const totalLeaveRecords = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('date_from')
    const isSortDirDesc = ref(true)
    const leave_statistics = ref([])
    const leave_records = ref([])

    const dataMeta = computed(() => {
        const localItemsCount = refLeaveRecordListTable.value ? refLeaveRecordListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalLeaveRecords.value,
        }
    })

    const refetchData = () => {
        refLeaveRecordListTable.value.refresh()
    }

    watch([currentPage, perPage, searchQuery, dateFromFilter, dateToFilter, leaveFilter], () => {
        refetchData()
    })

    const fetchLeaveRecords = (ctx, callback) => {
        store.dispatch('admin-leaverecord/fetchLeaveRecords',{
            q: searchQuery.value,
            //perPage: perPage.value,
            page: currentPage.value,
            sortBy: sortBy.value,
            sortDesc: isSortDirDesc.value,
            is_personal: true,
            date_from: dateFromFilter.value,
            date_to: dateToFilter.value,
            leave_code_id: leaveFilter.value,
        })
        .then(response =>{
            const {leaverecords, total, statistics} = response.data
            callback(leaverecords)
            totalLeaveRecords.value = total
            leave_statistics.value = statistics
            leave_records.value = leaverecords
        })
        .catch((error) => {
            toast({
                component: ToastificationContent,
                props: {
                    title: 'Error fetching leave records list',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger'
                }
            })
        })
    }

    return {
        fetchLeaveRecords,
        tableColumns,
        perPage,
        currentPage,
        totalLeaveRecords,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refLeaveRecordListTable,
        ability,
        refetchData,
        dateFromFilter,
        dateToFilter,
        leaveFilter,
        leave_statistics,
        leave_records
    }

}
<template>
  <b-modal
    id="modal-user-calendar"
    ok-title="submit"
    cancel-variant="outline-secondary"
    scrollable
    size="xl"
    title="Leave Calendar"
    hide-footer
    @change="checkChange()"
  >
    <full-calendar
            ref="refCalendar"
            :options="calendarOptions"
            class="full-calendar"
          
          />
  </b-modal>
</template>

<script>
import { BModal, VBModal } from "bootstrap-vue";
import store from "@/store";
import FullCalendar from "@fullcalendar/vue";
import userCalendar from "./useCalendar";

export default {
  data() {
    return {
      display: false,
    };
  },

/*   watch: {
    leaveData: {
      deep: true,
      handler() {
        console.log("LeaveData changed");
       // this.refreshEvents();
      },
    },
  }, */
  components: {
    BModal,
    VBModal,
    FullCalendar,
  },
  directives: {
    "b-modal": VBModal,
  },
  methods: {
    checkChange() {

     // this.resetView()     
    },
    hide() {
      this.$bvModal.hide("modal-calendar");
    },
  },
  mounted(){
      console.log('ModalCalendar mounted')
  },
  props: {
/*     leaveData: {
      type: Array,
      required: true,
    }, */
  },
  setup() {
    const {
      refCalendar,
      calendarOptions,
      refetchEvents,
      fetchEvents,
      resetView,
    } = userCalendar();

    fetchEvents()

    return{
      refCalendar,
      calendarOptions,
      refetchEvents,
      fetchEvents,
      resetView,
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";

#modal-user-calendar{

.fc .fc-daygrid-event-harness .fc-event {
    /* padding: 0.25rem 0.5rem; */
    padding:0;
}
.fc .fc-daygrid-day {
    padding: 0px;
}

 .modal-body {
    /* padding: 0.8rem 1.4rem; */
    padding:0;
}

 .modal {
    /* padding-right: 1rem; */
    /* padding-left: 1rem; */
    padding:0;
}
}
</style>
<template>
  <b-sidebar
    id="add-new-leave-record-sidebar"
    :visible="isAddNewLeaveRecordSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="
      (val) => $emit('update:is-add-new-leave-record-sidebar-active', val)
    "
  >
   
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">
          {{ button_text }} Leave Record [{{ leaverecordData.name }}]
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- Body -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-overlay
          :show="loading"
          spinner-variant="primary"
          spinner-type="grow"
          spinner-small
          rounded="sm"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <!-- Leave Record-->

            <h6>Leave Detail [Total Day: {{ leaverecordData.total_days }}]</h6>
            <hr />
            <b-form-group label="Attached Reference">
              <el-upload
                class="avatar-uploader"
                list-type="picture-card"
                ref="upload"
                :action="uploadUrl"
                :headers="headerObj"
                name="image_file"
                :file-list="fileList"
                multiple
                :limit="1"
                :data="leaverecordData"
                :on-change="onChange"
                :on-remove="toggleUpload"
                :before-remove="beforeRemove"
                :auto-upload="false"
                :class="{ hideUpload: !showUpload }"
              >
                <img
                  v-if="leaverecordData.file_path && false"
                  :src="getImgPath(leaverecordData.file_path)"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </b-form-group>

            <b-form-group
              class="required"
              label="Type of Leave"
              label-for="leave_code_id"
            >
              <v-select
                id="leave_code_id"
                v-model="leaverecordData.leave_code_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="leavecodes"
                label="description"
                :reduce="(option) => option.id"
                :clearable="false"
              >
                <template slot="option" slot-scope="option">
                  [{{ option.leave_code }}] {{ option.description }}
                </template>
              </v-select>
            </b-form-group>
            <validation-provider
              #default="validationContext"
              name="date_from"
              rules="required"
            >
              <b-form-group
                class="date_from required"
                label="Dates of Absence"
                label-for="date_from"
              >
                <b-form-datepicker
                  id="date_from"
                  :state="getValidationState(validationContext)"
                  placeholder="Choose a date"
                  local="en"
                  v-model="leaverecordData.date_from"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  @input="timeTrigger = !timeTrigger"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider
              #default="validationContext"
              name="date_to"
              rules="required"
            >
              <b-form-group class="date_to" label="to" label-for="date_to">
                <b-form-datepicker
                  id="date_to"
                  :state="getValidationState(validationContext)"
                  placeholder="Choose a date"
                  local="en"
                  v-model="leaverecordData.date_to"
                  :date-format-options="{
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                  }"
                  @input="timeTrigger = !timeTrigger"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <b-form-group
              class="full_day_bit"
              label=""
              label-for="full_day_bit"
            >
              <b-form-checkbox
                v-model="leaverecordData.full_day_bit"
                value="1"
                unchecked-value="0"
                class="custom-control-primary"
                :disabled="date_of_leave.length > 1 == 1 ? true : false"
                @change="full_day_bit_changed()"
              >
                <span
                  :class="{ time_selected: leaverecordData.full_day_bit == 1 }"
                  >Full Day</span
                >
              </b-form-checkbox>
            </b-form-group>
            <b-form-group class="time_from" label="" label-for="time_from" v-if="leaverecordData.full_day_bit == 0">
              <b-form-timepicker
                v-model="leaverecordData.time_from"
                locale="en"
                placeholder="time_from"
              />
            </b-form-group>
            <b-form-group class="time_to" label="" label-for="time_to" v-if="leaverecordData.full_day_bit == 0">
              <b-form-timepicker
                v-model="leaverecordData.time_to"
                locale="en"
                placeholder="time_to"
              />
            </b-form-group>

            <!-- Form Actions -->

            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                {{ button_text }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                Cancel
              </b-button>
            </div>
          </b-form>
        </b-overlay>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormTextarea,
  BFormCheckbox,
  BOverlay,
  BFormDatepicker,
  BFormTimepicker,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref, watch } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import { max } from "@/libs/validations.js";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import store from "@/store";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    BFormCheckbox,
    vSelect,
    BOverlay,
    BFormDatepicker,
    BFormTimepicker,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewLeaveRecordSidebarActive",
    event: "update:is-add-new-leave-record-sidebar-active",
  },
  props: {
    isAddNewLeaveRecordSidebarActive: {
      type: Boolean,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    leavecodes: {
      type: Array,
      required: true,
    },
    holidays:{
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      max,
      date_of_leave: []
    };
  },
  methods: {
    full_day_bit_changed() {
      //this.reset_bit()
      //this.leaveData.full_day_bit = 1
      this.leaverecordData.total_days = this.date_of_leave.length;
      if(((this.leaverecordData.full_day_bit+0.0) == 0))
      {
        this.leaverecordData.total_days = 0.5
      }else if(this.leaverecordData.date_from == this.leaverecordData.date_to)
      {
        this.leaverecordData.total_days = 1
      }
    },
    getDate(date) {
      return (
        date.getFullYear() +
        "-" +
        this.getMonthFormat(date.getMonth() + 1) +
        "-" +
        this.getMonthFormat(date.getDate())
      );
    },
    getMonthFormat(month) {
      if (month < 10) {
        return "0" + month;
      } else {
        return month;
      }
    },
    getBusinessDatesCount(startDate, endDate) {
      let count = 0;
      const curDate = new Date(startDate.getTime());
      this.date_of_leave = [];
      while (curDate <= endDate) {
        const dayOfWeek = curDate.getDay();
        const checkDate = this.getDate(curDate);
        if (
          dayOfWeek !== 0 &&
          dayOfWeek !== 6 &&
          !this.checkHoliday(checkDate)
        ) {
          count++;
          this.date_of_leave.push(checkDate);
        }
        curDate.setDate(curDate.getDate() + 1);
      }
      this.leaverecordData.total_days = count;
      if(((this.leaverecordData.full_day_bit+0.0) == 0))
      {
        this.leaverecordData.total_days = 0.5
      }
      return count;
    },
    checkHoliday(checkDate) {
      return this.holidays.some(function (el) {
        return el.holiday_date == checkDate;
      });
    },
    toggleUpload() {
      this.showUpload = !this.showUpload;
    },
    beforeRemove(file) {
      if (
        file.url ==
        process.env.VUE_APP_IMAGE_URL + this.leaverecordData.file_path
      ) {
        this.removeExistingPic = true;
        this.leaverecordData.file = null;
      }
    },
    onSubmit() {
      this.$swal({
        title: "確認儲存?",
        showCancelButton: true,
        confirmButtonText: "儲存",
        cancelButtonText: '取消',
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          let formData = new FormData();
          // leave_code_id date_from date_to time_from time_to full_day_bit
          formData.append("image_file", this.leaverecordData.file);
          formData.append("id", this.leaverecordData.id);
          formData.append("removeExistingPic", this.removeExistingPic);
          formData.append("leave_code_id", this.leaverecordData.leave_code_id);
          formData.append('total_days', this.leaverecordData.total_days)
          formData.append(
            "full_day_bit",
            this.leaverecordData.full_day_bit ? 1 : 0
          );
          formData.append("date_from", this.leaverecordData.date_from);
          formData.append("date_to", this.leaverecordData.date_to);
          if(!this.leaverecordData.full_day_bit)
          {
            formData.append("time_from", this.leaverecordData.time_from);
            formData.append("time_to", this.leaverecordData.time_to);
          }
          this.loading = true;
          store
            .dispatch("admin-leaverecord/updateUserLeaveRecord", formData)
            .then((response) => {
              this.loading = false;
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.removeExistingPic = false;
              this.$emit("refetch-data");
              this.$emit(
                "update:is-add-new-leave-record-sidebar-active",
                false
              );
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
  computed: {
    day_of_leave() {
      return this.leaverecordData.date_from - this.leaverecordData.date_to;
    },
    type_of_leave() {
      return this.leaverecordData.leave_code_id;
    },
  },
  watch: {
    timeTrigger() {
      console.log('timeTrigger')
      var startDate = new Date(this.leaverecordData.date_from);
      var endDate = new Date(this.leaverecordData.date_to);
      var day_of_leave = this.getBusinessDatesCount(startDate, endDate);
      if (day_of_leave > 1) {
        this.leaverecordData.full_day_bit = 1;
        this.leaverecordData.am_bit = 0;
        this.leaverecordData.pm_bit = 0;
      } else if (day_of_leave == 1) {
        if (this.leaverecordData.am_bit == 1 || this.leaverecordData.am_bit == 1) {
          this.leaverecordData.total_days = 0.5;
        }
      }
      //console.log(this.getBusinessDatesCount(startDate,endDate))
    },
    type_of_leave() {
      var type_of_leave = this.leavecodes.filter(
        (item) => item.id == this.leaverecordData.leave_code_id
      );
      type_of_leave = type_of_leave[0];
      this.leaverecordData.count_al_balance = type_of_leave.count_al_balance;
      this.leaverecordData.reason_bit = type_of_leave.reason_bit;
    },
    day_of_leave() {
      var startDate = new Date(this.leaverecordData.date_from);
      var endDate = new Date(this.leaverecordData.date_to);
      var day_of_leave = this.getBusinessDatesCount(startDate, endDate);
      if (day_of_leave > 1) {
        this.leaverecordData.full_day_bit = 1;
        this.leaverecordData.am_bit = 0;
        this.leaverecordData.pm_bit = 0;
      } else if (day_of_leave == 1) {
        if (this.leaverecordData.am_bit == 1 || this.leaverecordData.am_bit == 1) {
          this.leaverecordData.total_days = 0.5;
        }
      }
      //console.log(this.getBusinessDatesCount(startDate,endDate))
    },
  },
  setup(props, { emit }) {
    const loading = ref(false);
    const timeTrigger = ref(false)
    const blankLeaveRecordData = {
      id: 0,
      admin_remark: "",
      total_days: 0,
      date_from: "",
      date_to: "",
      time_from: "",
      time_to: "",
      count_al_balance: false,
      approve_bit: false,
      full_day_bit: false,
    };
    const button_text = ref("Add");
    // File Upload
    const showUpload = ref(true);
    const fileList = ref([]);
    const removeExistingPic = ref(false);
    const uploadUrl = ref(process.env.VUE_APP_SERVICE_URL + "/leave_record/");
    const headerObj = {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    };

    const leaverecordData = ref(
      JSON.parse(JSON.stringify(blankLeaveRecordData))
    );

    const resetleaverecordData = () => {
      leaverecordData.value = JSON.parse(JSON.stringify(blankLeaveRecordData));
    };

    const toast = useToast();

    watch(
      () => [props.id, props.isAddNewLeaveRecordSidebarActive],
      () => {
        if (props.id > 0 && props.isAddNewLeaveRecordSidebarActive) {
          /*
          leaverecordData.value.id = props.data.id;
          leaverecordData.value.admin_remark = props.data.admin_remark;
          leaverecordData.value.total_days = props.data.total_days;
          leaverecordData.value.leave_code_id = props.data.leave_code_id;
          leaverecordData.value.date_from = props.data.date_from;
          leaverecordData.value.date_to = props.data.date_to;
          leaverecordData.value.time_from = props.data.time_from;
          leaverecordData.value.time_to = props.data.time_to;
          leaverecordData.value.name = props.data.name;
          */
          // leave_code_id date_from date_to time_from time_to full_day_bit
           leaverecordData.value = { ...props.data };
          // leaverecordData.value.count_al_balance =
          //   props.data.count_al_balance == 1 ? true : false;
          // leaverecordData.value.approve_bit =
          //   props.data.approve_bit == 1 ? true : false;
          // leaverecordData.value.full_day_bit =
          //   props.data.full_day_bit == 1 ? true : false;
          // leaverecordData.value.full_day_bit =  props.data.full_day_bit
          if (leaverecordData.value.id > 0) {
            button_text.value = "Edit";
          } else {
            button_text.value = "Add";
          }

          if (leaverecordData.value.file_path) {
            console.log("file_detected");
            fileList.value = [
              {
                url:
                  process.env.VUE_APP_IMAGE_URL +
                  leaverecordData.value.file_path,
              },
            ];
            showUpload.value = false;
          }
          console.log("leaverecord", leaverecordData.value);
          timeTrigger.value = !timeTrigger.value
        } else {
          button_text.value = "Add";
        }
      }
    );

    const onChange = (file, fileLists) => {
      showUpload.value = !showUpload.value;
      leaverecordData.value.file = file.raw;
    };

    /*
    const onSubmit = () => {
      loading.value = true;
      store
        .dispatch("admin-leaverecord/updateLeaveRecord", leaverecordData.value)
        .then((response) => {
          loading.value = false;
          emit("update:is-add-new-leave-record-sidebar-active", false);
          emit("refetch-data");
          toast({
            component: ToastificationContent,
            props: {
              title: "Success",
              text: response.data.message,
              icon: "CheckIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          loading.value = false;
          toast({
            component: ToastificationContent,
            props: {
              title: "Fail",
              text: error.response.data.message,
              icon: "HeartIcon",
              variant: "danger",
            },
          });
        });
    };
    */

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetleaverecordData);

    return {
      leaverecordData,
      //onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      button_text,
      loading,

      // File upload
      showUpload,
      onChange,
      fileList,
      removeExistingPic,
      uploadUrl,
      headerObj,
      timeTrigger,
    };
  },
};
</script>


<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
#add-new-leave-record-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
.hideUpload > div {
  display: none;
}
</style>
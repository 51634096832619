<template>
  <b-sidebar
    id="add-new-overtime-sidebar"
    :visible="isAddNewOvertimeSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-overtime-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">{{ button_text }} OT Record</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- Body -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-overlay
          :show="loading"
          spinner-variant="primary"
          spinner-type="grow"
          spinner-small
          rounded="sm"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <!-- OT Record-->

            
            <!-- Title -->
            <validation-provider
              #default="validationContext"
              name="title"
              rules="required"
            >
              <b-form-group
                class="title required"
                label="Title"
                label-for="title"
              >
                <b-form-input
                  id="title"
                  :state="getValidationState(validationContext)"
                  v-model="overtimeData.title"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Remark -->
            <validation-provider
              #default="validationContext"
              name="remark"
              rules="required"
            >
              <b-form-group
                class="remark required"
                label="Remark"
                label-for="remark"
              >
                <b-form-textarea
                  id="remark"
                  v-model="overtimeData.remark"
                  :state="getValidationState(validationContext)"
                  autofocus
                  trim
                   style="display:none;"
                />
                <quill-editor
                    v-model="overtimeData.remark"
                />   

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Off Work Bit -->
            <b-form-group>
                <b-form-checkbox
                checked="true"
                name="check-button"
                switch
                inline
                v-model="overtimeData.off_work_bit"
                >
                    假日 / 非Working Hour OT
                </b-form-checkbox>
            </b-form-group>
            <!-- Time From -->
            <validation-provider
              #default="validationContext"
              name="time_from"
              rules="required"
            >
            <b-form-group class="time_from" :label="(overtimeData.off_work_bit == 1)?'From':'上班時間'" label-for="time_from">
              <b-form-input style="display:none;" v-model="overtimeData.time_from" :state="getValidationState(validationContext)" type="text" class="mb-0" placeholder="time_from" />
              <flat-pickr
                v-model="overtimeData.time_from"
                class="form-control"
                :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
                />
              <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
            </validation-provider>
            <!-- Time To -->
            <validation-provider
              #default="validationContext"
              name="time_to"
              rules="required"
            >
            
            <b-form-group class="time_to" :label="(overtimeData.off_work_bit == 1)?'To':'下班時間'" label-for="time_to">
              <b-form-input  style="display:none;" v-model="overtimeData.time_to" :state="getValidationState(validationContext)" type="text" class="mb-0" placeholder="time_to" />
              <flat-pickr
                v-model="overtimeData.time_to"
                class="form-control"
                :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
                />
                <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
               </b-form-invalid-feedback>
            </b-form-group>
            
            </validation-provider>
            
            <!-- Form Actions -->

            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                {{ button_text }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                Cancel
              </b-button>
            </div>
          </b-form>
        </b-overlay>
      </validation-observer>
    </template></b-sidebar
  >
</template>


<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormTextarea,
  BFormCheckbox,
  BOverlay,
  BFormDatepicker,
  BFormTimepicker,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref, watch } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import { max } from "@/libs/validations.js";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import store from "@/store";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from 'vue-flatpickr-component'
import VueSweetalert2 from 'vue-sweetalert2'
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'


export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    BFormCheckbox,
    vSelect,
    BOverlay,
    BFormDatepicker,
    BFormTimepicker,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    quillEditor,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewOvertimeSidebarActive",
    event: "update:is-add-new-overtime-sidebar-active",
  },
  props: {
    isAddNewOvertimeSidebarActive: {
      type: Boolean,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      max,
    };
  },
  methods:{
    getOTHour(hour)
    {
      if(hour < 0)
      {
        return 0
      }else{
        return Math.floor(hour/0.5)*0.5
      }      
    },
     getHoursDifference(startTime, endTime){
      var timeStart = new Date(startTime)
      var timeEnd = new Date(endTime)
      var diff = timeEnd.getTime() - timeStart.getTime();
      var Hrs = diff / (1000 * 60 * 60);
      return Hrs
    },
    onSubmit(){
      this.overtimeData.total_hour = this.getHoursDifference(this.overtimeData.time_from, this.overtimeData.time_to)

      if(this.overtimeData.off_work_bit == false)
      {
        this.overtimeData.total_hour = this.overtimeData.total_hour - 8.5
      }

      this.overtimeData.total_hour = this.getOTHour(this.overtimeData.total_hour)

      console.log(this.overtimeData.total_hour)
      this.$swal({
          title: '確認儲存?',
          text: 'Total OT Hours: '+this.overtimeData.total_hour,
          showCancelButton: true,
          confirmButtonText: '儲存',
          cancelButtonText: '取消',
          customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
      })
      
      .then(result => {
          if(result.value) {
            this.loading = true;
            store
              .dispatch("admin-overtime/addOvertime", this.overtimeData)
              .then((response) => {
                this.loading = false;
                this.$emit("update:is-add-new-overtime-sidebar-active", false);
                this.$emit("refetch-data");
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Success",
                    text: response.data.message,
                    icon: "CheckIcon",
                    variant: "success",
                  },
                });
              })
              .catch((error) => {
                this.loading = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Fail",
                    text: error.response.data.message,
                    icon: "HeartIcon",
                    variant: "danger",
                  },
                });
              });
          }
      })
    }
  },
  setup(props, { emit }) {
    const loading = ref(false);

    const blankOvertimeData = {
            id: 0,
            title: '',
            remark: '',
            time_from: '',
            time_to: '',
            ot_date: '',
            total_hour: 0,
            off_work_bit: false,
    };
    const button_text = ref("Add");

    const overtimeData = ref(JSON.parse(JSON.stringify(blankOvertimeData)));

    const resetovertimeData = () => {
      overtimeData.value = JSON.parse(JSON.stringify(blankOvertimeData));
    };

    const toast = useToast();

    watch(
      () => [props.id, props.isAddNewOvertimeSidebarActive],
      () => {
        if (props.id > 0 && props.isAddNewOvertimeSidebarActive) {
          overtimeData.value.id = props.data.id;
          overtimeData.value.title = props.data.title;
          overtimeData.value.remark = props.data.remark;
          overtimeData.value.time_from = props.data.time_from;
          overtimeData.value.time_to = props.data.time_to;
          //overtimeData.value.ot_date = props.data.ot_date;
          overtimeData.value.total_hour = props.data.total_hour;
          overtimeData.value.admin_remark = props.data.admin_remark;
          overtimeData.value.approve_bit =
            props.data.approve_bit == 1 ? true : false;
          overtimeData.value.off_work_bit =
            props.data.off_work_bit == 1 ? true : false;
            
          if (overtimeData.value.id > 0) {
            button_text.value = "Edit";
          } else {
            button_text.value = "Add";
          }
          console.log("overtime", overtimeData.value);
        } else {
          button_text.value = "Add";
        }
      }
    );

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetovertimeData);

    return {
      overtimeData,
      refFormObserver,
      getValidationState,
      resetForm,
      button_text,
      loading,
    };
  },
};
</script>



<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
#add-new-overtime-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
.ql-editor{
	height:200px;
}

</style>
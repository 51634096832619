<template>
  <b-card no-body>
    <!--
    <b-card-header class="pb-50">
      <h5>過濾器</h5>
    </b-card-header>
    -->
    <b-card-body>
      <b-row style="">
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>Date From</label>
          <b-form-datepicker
            id="date_from"
            placeholder="Date From"
            local="en"
            :value="dateFromFilter"
            @input="(val) => $emit('update:dateFromFilter', val)"
            :date-format-options="{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }"
          />
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>Date To</label>
          <b-form-datepicker
            id="date_to"
            placeholder="Date To"
            local="en"
            :value="dateToFilter"
            @input="(val) => $emit('update:dateToFilter', val)"
            :date-format-options="{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }"
          />
        </b-col>
        <b-col cols="12" md="2" class="mb-md-0 mb-2">
          <label></label>
          <div>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              size="sm"
              variant="primary"
              @click="reset"
              style="height: 40px"
            >
              Reset
            </b-button>
          </div>
        </b-col>
        <b-col cols="12" md="4" class="mb-md-0 mb-2">
          <label></label>
        <div class="d-flex align-items-center justify-content-end">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                :variant="(remarkFilter)?'':'success'"
                @click="triggerRemark()"
                style="height:40px;"
              >
                Hidden Remark
              </b-button>
              </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormDatepicker,
  BButton,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";

export default {
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    BFormDatepicker,
    BButton,
  },
  methods: {
    reset() {
      this.$emit("update:dateFromFilter", null);
      this.$emit("update:dateToFilter", null);
    },
    triggerRemark(){
      this.$emit('update:remarkFilter', !this.remarkFilter)
    }
  },
  props: {

    dateFromFilter: {
      type: [String, null],
      default: null,
    },
    dateToFilter: {
      type: [String, null],
      default: null,
    },
    remarkFilter: {
      type:[Boolean, false],
        default:false,
    },

  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

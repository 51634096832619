<template>
<b-modal
    id="modal-work-user"
    ok-title="submit"
    cancel-variant="outline-secondary"
    scrollable
    size="lg"
    title="Working Detail"
    hide-footer
  >
    <b-card>
    <b-card-body>
        <h5>上班時間： {{workData.time_from}} - {{workData.time_to}}</h5>
        <h6>Remark: </h6>
        <div v-html="workData.remark"></div>
    <hr>
    <app-timeline>
    <app-timeline-item
       v-for="item in workData.work_records"
       :key="item.id"
      
    >
        <small class="text-muted">{{item.time_from}} - {{item.time_to}}</small>
        <div v-html="item.work_remark">

        </div>
    </app-timeline-item>
    </app-timeline>
    </b-card-body>
    </b-card>
</b-modal>
</template>

<script>
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import {
    BModal,
    BCard,
    BCardBody,
} from "bootstrap-vue"

export default {
    components:{
        BModal,
        BCard,
        BCardBody,
        AppTimeline,
        AppTimelineItem,    
    },
    methods:{
        hide()
        {
            this.$bvModal.hide("modal-work")
        }
    },
    props: {
        workData:{
            type: Object,
            required: true,
        }
    }
    
}
</script>
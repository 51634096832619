<template>
  <b-modal
    id="modal-leave"
    ok-title="submit"
    cancel-variant="outline-secondary"
    scrollable
    size="lg"
    title="請假請求"
    hide-footer
    no-close-on-backdrop
    centered
  >
    <div class="d-flex">
      <h5 class="mb-0">請假資料</h5>
    </div>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <b-form class="mt-1" @submit.prevent="handleSubmit(onSubmit)">
          <b-row>
            <!-- Field: 請假類型-->
            <b-col cols="12" md="12">
              <validation-provider
                #default="validationContext"
                name="請假類型"
                rules="required"
              >
                <b-form-group
                  class="required"
                  label="請假類型"
                  label-for="leave_code_id"
                  label-cols-md="4"
                >
                  <b-form-input
                    style="display: none"
                    v-model="leaveData.leave_code_id"
                    :state="getValidationState(validationContext)"
                    type="text"
                    class="mb-0"
                    placeholder="leave_code_id"
                  />
                  <v-select
                    :class="{ warning_border: validationContext.errors[0] }"
                    id="leave_code_id"
                    v-model="leaveData.leave_code_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="leavecodes"
                    label="description"
                    :reduce="(option) => option.id"
                    :clearable="false"
                  >
                    <template slot="option" slot-scope="option">
                      [{{ option.leave_code }}] {{ option.description }}
                    </template>
                  </v-select>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Field: 請假原因-->

            <b-col cols="12" md="12" v-if="leaveData.reason_bit">
              <validation-provider
                #default="validationContext"
                name="reason"
                rules="required"
              >
                <b-form-group
                  class="required"
                  label="請假原因"
                  label-for="reason"
                  label-cols-md="4"
                >
                  <b-form-input
                    id="reason"
                    v-model="leaveData.description"
                    :state="getValidationState(validationContext)"
                    placeholder="Write your reason"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: 請假日期 -->
            <b-col cols="12" md="12">
              <validation-provider
                #default="validationContext"
                name="date_from"
                rules="required|date_before:@date_to"
              >
                <b-form-group
                  class="date_from required"
                  label="請假日期"
                  label-for="date_from_1"
                  label-cols-md="4"
                >
                  <b-form-datepicker
                    id="date_from_1"
                    :state="getValidationState(validationContext)"
                    placeholder="Choose a date"
                    local="en"
                    v-model="leaveData.date_from"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                    :date-disabled-fn="dateDisabled"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!-- Field: Dates of Absence To -->
            <b-col cols="12" md="12">
              <validation-provider
                #default="validationContext"
                name="date_to"
                rules="required"
              >
                <b-form-group
                  class="date_to"
                  label=""
                  label-for="date_to_1"
                  label-cols-md="4"
                >
                  <b-form-datepicker
                    :date-disabled-fn="dateDisabled"
                    id="date_to_1"
                    :state="getValidationState(validationContext)"
                    placeholder="Choose a date"
                    local="en"
                    v-model="leaveData.date_to"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!--Field: Full Day -->
            <b-col cols="12" md="12">
              <b-form-group
                class="full_day_bit"
                label=""
                label-for="full_day_bit"
                label-cols-md="4"
              >
                <b-form-checkbox
                  v-model="leaveData.full_day_bit"
                  value="1"
                  unchecked-value="0"
                  class="custom-control-primary"
                  :disabled="date_of_leave.length > 1 == 1 ? true : false"
                  @change="full_day_bit_changed()"
                >
                  <span :class="{ time_selected: leaveData.full_day_bit == 1 }"
                    >Full Day</span
                  >
                </b-form-checkbox>
              </b-form-group>
            </b-col>

            <!--Field: Time From -->
            <b-col cols="12" md="12" v-if="leaveData.full_day_bit != 1">
              <validation-provider
                #default="validationContext"
                name="date_from"
                rules="required"
              >
                <b-form-group
                  class="am_bit"
                  label=""
                  label-for="time_from"
                  label-cols-md="4"
                >
                  <el-time-select
                      style="width: 100%"
                      placeholder="From"
                      v-model="leaveData.time_from"
                      :picker-options="{
                        start: '07:00',
                        step: '00:30',
                        end: '24:00',
                      }"
                      :disabled="
                      (date_of_leave.length > 1 ||
                        leaveData.full_day_bit == 1) == 1
                        ? true
                        : false
                    "
                    >
                    </el-time-select>
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <!--Field: Time To -->
            <b-col cols="12" md="12" v-if="leaveData.full_day_bit != 1">
              <validation-provider
                #default="validationContext"
                name="date_from"
                rules="required"
              >
                <b-form-group
                  class="pm_bit"
                  label=""
                  label-for="time_to"
                  label-cols-md="4"
                >
                   <el-time-select
                      style="width: 100%"
                      placeholder="From"
                      v-model="leaveData.time_to"
                      :picker-options="{
                        start: '07:00',
                        step: '00:30',
                        end: '24:00',
                      }"
                      :disabled="
                      (date_of_leave.length > 1 ||
                        leaveData.full_day_bit == 1) == 1
                        ? true
                        : false
                    "
                    >
                    </el-time-select>
                 
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: 附加檔案 -->
            <b-col cols="12" md="12">
              <b-form-group
                label="附加檔案"
                label-for="attached_reference"
                label-cols-md="4"
              >
                <b-form-file
                  accept=".jpg, .png, .gif, .jpeg, .webp, .svg"
                  id="file-default"
                  @change="handleFileUpload($event)"
                />
              </b-form-group>
            </b-col>

            <b-col>
              <hr />
            </b-col>
            <!-- Field: 請假日 -->
            <b-col cols="12" v-if="date_of_leave.length > 0">
              <b-form-group label="請假日" label-cols-md="4">
                <b-badge
                  variant="success"
                  v-for="(data, index) in date_of_leave"
                  :key="index"
                >
                  {{ data }}
                </b-badge>
              </b-form-group>
            </b-col>
            <!-- Field: 總天數 -->
            <b-col cols="12" md="12" v-if="date_of_leave.length > 0">
              <b-form-group
                label="總天數"
                label-for="total_days"
                label-cols-md="4"
              >
                <b-form-input
                  id="total_days"
                  v-model="leaveData.total_days"
                  disabled
                />
              </b-form-group>
            </b-col>
            <!-- Form Actions -->
            <b-col v-if="date_of_leave.length > 0">
              <hr />
            </b-col>
            <b-col cols="12" style="margin:0; padding: 0">
              <div class="d-flex justify-content-end">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-1"
                  type="submit"
                >
                  Save
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </b-overlay>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BButton,
  BModal,
  VBModal,
  BCardText,
  BOverlay,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckboxGroup,
  BFormDatepicker,
  BRow,
  BCol,
  BFormInvalidFeedback,
  BCardBody,
  BBadge,
  BFormCheckbox,
  BFormFile,
  BFormTimepicker
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { heightTransition } from "@core/mixins/ui/transition";
import leaveStoreModule from "../leaveStoreModule";
import leavecodeStoreModule from "@/views/setting/leavecode/leavecodeStoreModule";
import holidayStoreModule from "@/views/setting/holiday/holidayStoreModule.js";

extend("date_before", {
  params: ["target"],
  validate(value, { target }) {
    return value <= target || !target;
  },
  message: "Date_from must be equal to or before date_to",
});

export default {
  components: {
    BButton,
    BModal,
    VBModal,
    BCardText,
    BOverlay,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BFormInvalidFeedback,
    BFormCheckboxGroup,
    BFormDatepicker,
    BCardBody,
    BBadge,
    BFormCheckbox,
    BFormFile,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BFormTimepicker
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  methods: {
    hide() {
      this.$bvModal.hide("modal-leave");
    },
    full_day_bit_changed() {
      //this.reset_bit()
      //this.leaveData.full_day_bit = 1
      this.leaveData.total_days = this.date_of_leave.length;
      if(((this.leaveData.full_day_bit+0.0) == 0))
      {
        this.leaveData.total_days = 0.5
      }else if(this.leaveData.date_from == this.leaveData.date_to)
      {
        this.leaveData.total_days = 1
      }
    },
    reset_bit() {
      this.leaveData.full_day_bit = 0;
    },
    handleFileUpload(event) {
      this.file = event.target.files[0];
    },
    onSubmit() {
      let formData = new FormData();
      formData.append("file", this.file);
      formData.append("id", this.leaveData.id);
      formData.append("leave_code_id", this.leaveData.leave_code_id);
      formData.append("description", this.leaveData.description);
      formData.append("full_day_bit", this.leaveData.full_day_bit);
      formData.append("time_from", this.leaveData.time_from);
      formData.append("time_to", this.leaveData.time_to);
      formData.append("date_from", this.leaveData.date_from);
      formData.append("date_to", this.leaveData.date_to);
      formData.append("total_days", this.leaveData.total_days);
      formData.append("count_al_balance", this.leaveData.count_al_balance);
      this.$swal({
        title: "確認儲存?",
        showCancelButton: true,
        confirmButtonText: "儲存",
        cancelButtonText: '取消',
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          store
            .dispatch("admin-leaverecord/addLeaveRecord", formData)
            .then((response) => {
              this.loading = false;
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.$emit("refetch-data");
              this.hide()
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    getBusinessDatesCount(startDate, endDate) {
      let count = 0;
      const curDate = new Date(startDate.getTime());
      this.date_of_leave = [];
      while (curDate <= endDate) {
        const dayOfWeek = curDate.getDay();
        const checkDate = this.getDate(curDate);
        if (
          dayOfWeek !== 0 &&
          dayOfWeek !== 6 &&
          !this.checkHoliday(checkDate)
        ) {
          count++;
          this.date_of_leave.push(checkDate);
        }
        curDate.setDate(curDate.getDate() + 1);
      }
      this.leaveData.total_days = count;
      if(((this.leaveData.full_day_bit+0.0) == 0))
      {
        this.leaveData.total_days = 0.5
      }
      return count;
    },
    checkHoliday(checkDate) {
      return this.holidays.some(function (el) {
        return el.holiday_date == checkDate;
      });
    },
    //Return date in YYYY-MM-DD format
    getDate(date) {
      return (
        date.getFullYear() +
        "-" +
        this.getMonthFormat(date.getMonth() + 1) +
        "-" +
        this.getMonthFormat(date.getDate())
      );
    },
    getMonthFormat(month) {
      if (month < 10) {
        return "0" + month;
      } else {
        return month;
      }
    },
    dateDisabled(ymd, date) {
      const weekday = date.getDay();
      return weekday === 0 || weekday === 6 || this.checkHoliday(ymd);
    },
  },
  computed: {
    day_of_leave() {
      return this.leaveData.date_from - this.leaveData.date_to;
    },
    type_of_leave() {
      return this.leaveData.leave_code_id;
    },
  },
  watch: {
    type_of_leave() {
      var type_of_leave = this.leavecodes.filter(
        (item) => item.id == this.leaveData.leave_code_id
      );
      type_of_leave = type_of_leave[0];
      this.leaveData.count_al_balance = type_of_leave.count_al_balance;
      this.leaveData.reason_bit = type_of_leave.reason_bit;
    },
    day_of_leave() {
      var startDate = new Date(this.leaveData.date_from);
      var endDate = new Date(this.leaveData.date_to);
      var day_of_leave = this.getBusinessDatesCount(startDate, endDate);
      if (day_of_leave > 1) {
        this.leaveData.full_day_bit = 1;
        this.leaveData.am_bit = 0;
        this.leaveData.pm_bit = 0;
      } else if (day_of_leave == 1) {
        if (this.leaveData.am_bit == 1 || this.leaveData.am_bit == 1) {
          this.leaveData.total_days = 0.5;
        }
      }
      //console.log(this.getBusinessDatesCount(startDate,endDate))
    },
  },
  setup() {
    const leaveData = ref({});
    const leavecodes = ref([]);
    const loading = ref(false);
    const holidays = ref([]);
    const date_of_leave = ref([]);
    const file = ref(null);
    const blankLeaveData = {
      id: 0,
      reason: "",
      date_from: "",
      date_to: "",
      full_day_bit: 1,
      am_bit: 0,
      pm_bit: 0,
      reason_bit: 0,
      count_al_balance: 0,
    };
    const LEAVE_STORE_MODULE_NAME = "leave";

    const SETTING_LEAVE_CODE_STORE_MODULE_NAME = "setting-leavecode";

    const SETTING_HOLIDAY_STORE_MODULE_NAME = "setting-holiday";

    if (!store.hasModule(LEAVE_STORE_MODULE_NAME))
      store.registerModule(LEAVE_STORE_MODULE_NAME, leaveStoreModule);

    if (!store.hasModule(SETTING_LEAVE_CODE_STORE_MODULE_NAME))
      store.registerModule(
        SETTING_LEAVE_CODE_STORE_MODULE_NAME,
        leavecodeStoreModule
      );

    if (!store.hasModule(SETTING_HOLIDAY_STORE_MODULE_NAME))
      store.registerModule(
        SETTING_HOLIDAY_STORE_MODULE_NAME,
        holidayStoreModule
      );

    onUnmounted(() => {
      if (store.hasModule(SETTING_LEAVE_CODE_STORE_MODULE_NAME))
        store.unregisterModule(SETTING_LEAVE_CODE_STORE_MODULE_NAME);
      if (store.hasModule(SETTING_HOLIDAY_STORE_MODULE_NAME))
        store.unregisterModule(SETTING_HOLIDAY_STORE_MODULE_NAME);
      if (store.hasModule(LEAVE_STORE_MODULE_NAME))
        store.unregisterModule(LEAVE_STORE_MODULE_NAME);
    });

    const refetchData = () => {
      store
        .dispatch(SETTING_LEAVE_CODE_STORE_MODULE_NAME + "/fetchLeaveCodes")
        .then((response) => {
          leavecodes.value = response.data.leavecodes;
        });

      store
        .dispatch(SETTING_HOLIDAY_STORE_MODULE_NAME + "/fetchHolidays")
        .then((response) => {
          holidays.value = response.data.holidays;
        });
    };

    const resetData = () => {
      leaveData.value = JSON.parse(JSON.stringify(blankLeaveData));
    };

    refetchData();
    resetData();

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetData);

    return {
      leaveData,
      leavecodes,
      refFormObserver,
      getValidationState,
      resetForm,
      loading,
      file,
      holidays,
      date_of_leave,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
.repeater-form {
    overflow: hidden;
    transition: .35s height;
}

.required>label::after {
    content: " **";
    color: red;
}

.date_from > label::before {
    content: "From";
    position:absolute;
    right:5%;
}

.date_to > label::before {
    content: "To";
    position:absolute;
    right:5%;
}

.vs__selected path {
    fill: white;
}

.v-select {
    &.item-selector-title,
    &.payment-selector {
        background-color: #fff;
        .dark-layout & {
            background-color: unset;
        }
    }
}

.add-new-group-header {
    color: #28c76f;
    margin-left: 15px;
}

.time_selected{
  background:$primary;
  padding:3px 10px; 
  border-radius:3px; 
  color:white;
}

.badge {
  padding: 10px !important;
  margin: 10px 20px 10px 0px;
  background:$primary;
}

.warning_border{
    border:1px solid red;
    border-radius:6px;
}

@media only screen and (max-width: 600px) {
  .badge {
    width: 100%;
  }
}

.leave-card{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    background:#fcfcf8;
}

#date_from__dialog_{
  z-index:999;
}

 #modal-leave___BV_modal_content_ > .modal-body {
    padding: 0.8rem 1.4rem;

}

 #modal-leave___BV_modal_content_  > .modal {
    padding-right: 1rem; 
    padding-left: 1rem; 
}


</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"no-body":""}},[_c('b-card-body',[_c('b-row',{},[_c('b-col',{staticClass:"mb-md-0 mb-2",attrs:{"cols":"12","md":"3"}},[_c('label',[_vm._v("Date From")]),_c('b-form-datepicker',{attrs:{"id":"date_from","placeholder":"Date From","local":"en","value":_vm.dateFromFilter,"date-format-options":{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          }},on:{"input":function (val) { return _vm.$emit('update:dateFromFilter', val); }}})],1),_c('b-col',{staticClass:"mb-md-0 mb-2",attrs:{"cols":"12","md":"3"}},[_c('label',[_vm._v("Date To")]),_c('b-form-datepicker',{attrs:{"id":"date_to","placeholder":"Date To","local":"en","value":_vm.dateToFilter,"date-format-options":{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          }},on:{"input":function (val) { return _vm.$emit('update:dateToFilter', val); }}})],1),_c('b-col',{staticClass:"mb-md-0 mb-2",attrs:{"cols":"12","md":"2"}},[_c('label'),_c('div',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticStyle:{"height":"40px"},attrs:{"size":"sm","variant":"primary"},on:{"click":_vm.reset}},[_vm._v(" Reset ")])],1)]),_c('b-col',{staticClass:"mb-md-0 mb-2",attrs:{"cols":"12","md":"4"}},[_c('label'),_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticStyle:{"height":"40px"},attrs:{"size":"sm","variant":(_vm.remarkFilter)?'':'success'},on:{"click":function($event){return _vm.triggerRemark()}}},[_vm._v(" Hidden Remark ")])],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }